// This scripts are meant only to make the markup more interactive
// Please do not use on production unless you think they perfectly fits your needs
$(() => {
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    $.prototype.dataToggle = function(cfg = {}) {
        class DataToggle {
            constructor($el, instanceId) {
                this.instanceId = instanceId;
                this.$el = $el;
                this.$originalEl = $el;
                this.body = $('body');
                this.bindKey = 'click.data_toggle.' + this.instanceId;

                if (this.$el[0].hasAttribute('data-toggle-target')) {
                    this.$el = $(this.$el.data('toggle-target'));
                } else {
                    this.$el = this.$el;
                }

                // class to be toggled
                this.toggleClass = this.$el.data('toggle-class') || 'active';

                // when present will ignore click inside dropdown menu
                this.$togglePrevent = this.$el.find('[data-toggle-prevent]');
                
                if (this.$togglePrevent.length == 0) {
                    if (this.$el[0].hasAttribute('data-toggle-prevent-self')) {
                        this.$togglePrevent = this$el.data('toggle-prevent-self');
                    } else {
                        this.$togglePrevent = false;
                    }
                }

                this.bind();
            }

            async bind() {
                this.$originalEl.unbind(this.el).bind(this.bindKey, async (e) => {
                    e.preventDefault();

                    if (this.$el.hasClass(this.toggleClass)) {
                        return;
                    }

                    this.$el.addClass(this.toggleClass);

                    await sleep(10);

                    this.body.unbind(this.bindKey);
                    this.body.bind(this.bindKey, async (e) => {
                        if (this.$togglePrevent && (
                                this.$togglePrevent.has($(e.target)).length > 0
                            ) && !this.$togglePrevent.is($(e.target))) {
                            e.preventDefault();
                            e.stopPropagation();
                        } else {
                            await sleep(10);

                            this.body.unbind(this.bindKey);
                            this.$el.removeClass(this.toggleClass);
                        }
                    });
                });
            }
        }

        let instanceId = 0;

        for (let i = 0; i < this.length; i++) {
            new DataToggle($(this[i]), instanceId++);
        }
    };

    $('[data-toggle').dataToggle();
});